import { fonts, purify } from '@vfit/shared/themes';
import { CustomText } from '@vfit/shared/atoms';
import { useState } from 'react';
import {
  InflationContainer,
  DisclaimerContainer,
  ModalContent,
  ModalTitle,
  ProductCardSpecItemAction,
  Title,
  Disclaimer,
} from './inflation.style';
import { IInflationProps } from './inflation.models';
import VfModal from '../VfModal/vfModal';

const Inflation = ({
  InflationTitle,
  popup,
  color,
  inflationDisclaimer,
  popupTitle,
  compactStyle,
  isGlobalPromo = false,
}: IInflationProps) => {
  const [pInflationTitle, pInflationDisclaimer] = purify([
    InflationTitle || '',
    inflationDisclaimer || '',
  ]);
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });
  return (
    <>
      <DisclaimerContainer>
        {inflationDisclaimer && (
          <Disclaimer dangerouslySetInnerHTML={{ __html: pInflationDisclaimer || '' }} />
        )}
      </DisclaimerContainer>
      <InflationContainer compact={compactStyle}>
        <Title
          style={{ color: `${color}` }}
          dangerouslySetInnerHTML={{
            __html: pInflationTitle || '',
          }}
          isGloballPromo={isGlobalPromo}
        />
        {popup && (
          <div
            className='tooltip'
            role="presentation"
            onClick={() =>
              setModal({
                title: popupTitle || InflationTitle,
                text: popup,
                show: true,
              })
            }
          >
            <ProductCardSpecItemAction>+</ProductCardSpecItemAction>
          </div>
        )}
      </InflationContainer>

      <VfModal
        height={563}
        isOpen={modal.show}
        handleClose={() => setModal({ show: false, text: '', title: '' })}
      >
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
            </ModalTitle>
          )}
          <ModalContent>
            <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
          </ModalContent>
        </>
      </VfModal>
    </>
  );
};

export default Inflation;

