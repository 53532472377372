import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

const circleBtnWidth = 24;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${pxToCssFont(18, 24)};
  font-weight: 700;
  width: 100%;

  div {
    ${pxToCssFont(20, 30)};
    font-weight: 400;
    font-family: ${fonts.regular};
  }

  div:first-of-type {
    width: calc(100% - ${circleBtnWidth}px - 15px);
  }
`;

const btnSize = 26;
export const BtnModalPromo = styled.button`
  flex-shrink: 0;
  border: none;
  background: ${colors.$e60000};
  color: white;
  width: ${btnSize}px;
  height: ${btnSize}px;
  border-radius: 50%;
  font-family: ${fonts.light};
  transition: all 0.3s;
  margin-left: 15px;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const ModalDetailText = styled.div`
  div {
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    font-weight: 400;
    color: ${colors.$262626};
    margin: 0;
    max-width: none;
  }
`;
