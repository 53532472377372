import { CustomText } from '@vfit/shared/atoms';
import { useState } from 'react';
import VfModal from '../VfModal/vfModal';
import { IPromotion } from '../OfferMap/offerMap.models';
import { Box, BtnModalPromo, ModalDetailText } from './promotion.style';

export const Promo = (promotion: { promo: IPromotion }) => {
  const [openModal, setOpenModal] = useState(false);
  const { promo } = promotion;

  return (
    <Box>
      <CustomText text={promo.shortDescription} textAlign="left" />
      <BtnModalPromo onClick={() => setOpenModal(true)}>
        <span>+</span>
      </BtnModalPromo>
      <VfModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
        <>
          <h3>{promo.title}</h3>
          <ModalDetailText>
            <CustomText text={promo.description || ''} textAlign="left" />
          </ModalDetailText>
        </>
      </VfModal>
    </Box>
  );
};

export default Promo;
