import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Justify = styled.div<{ bottomPart?: boolean }>`
  padding: ${({ bottomPart }) => (bottomPart ? '16px 24px 24px' : '16px 24px')};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    padding: ${({ bottomPart }) => (bottomPart ? '24px 36px 32px' : '32px 36px 24px')};
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    margin: 20px 36px 0;
  }
`;

export const OfferMapContainer = styled.div`
  overflow: hidden;
  margin: 64px 32px 0;
  background: ${colors.$ffffff};
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;

  @media (min-width: ${breakpoints.desktop}) {
    div:first-child {
      height: inherit;
    }

    margin: inherit;
    border: none;
    border-radius: inherit;
    width: 147%;
    transform: translate(-32%);
  }

  * {
    margin: 0;
  }

  .gmnoprint {
    display: none;
  }

  del {
    font-size: 1.5rem;
    margin-bottom: 2px;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: 1.25rem;
    }
  }
`;

export const ManageMapHeight = styled.header<{ height: number | null }>`
  height: ${(p) => (p.height ? p.height : 160)}px;
`;

export const Title = styled.h3`
  margin-top: 8px;

  div {
    ${pxToCssFont(36, 45)};
    font-family: ${fonts.exbold};
    font-weight: 400;

    @media (max-width: ${breakpoints.tablet}) {
      ${pxToCssFont(24, 30)};
    }
  }
`;

export const OfferContainer = styled.div<{ top: number }>`
  max-width: 515px;
  background: ${colors.$ffffff};
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  position: absolute;
  right: 9%;
  top: ${(p) => `${p.top}px`};
  display: flex;
  flex-direction: column;

  .cta {
    div {
      text-decoration: underline;
      font-family: ${fonts.regular};
      ${pxToCssFont(16, 22)};
      font-weight: 700;
      margin: 32px 36px 0;
    }
  }

  .title {
    div {
      font-family: ${fonts.exbold};
      ${pxToCssFont(36, 45)};
      margin: 0 36px;
    }
  }
`;

export const SubCard = styled.div`
  border-top: 1px solid ${colors.$bebebe};
  min-height: 200px;
  min-width: 500px;

  @media (max-width: ${breakpoints.tablet}) {
    min-width: unset;
  }

  .bottomTitle {
    div {
      font-family: ${fonts.regular};
      text-transform: uppercase;
      ${pxToCssFont(18, 24)};
      font-weight: 700;
      margin: 24px 36px 0;
    }
  }

  .subDescTitle {
    div {
      font-family: ${fonts.regular};
      text-transform: uppercase;
      ${pxToCssFont(18, 24)};
      font-weight: 700;
      color: ${colors.$7e7e7e};
      margin: 10px 36px 0;
    }
  }

  .subDescription {
    div {
      font-family: ${fonts.regular};
      font-weight: 400;
      ${pxToCssFont(18, 24)};

      @media (max-width: ${breakpoints.tablet}) {
        ${pxToCssFont(16, 22)};
      }
    }
  }

  .description {
    div {
      font-family: ${fonts.regular};
      ${pxToCssFont(20, 30)};
      margin: 16px 0;
      font-weight: 400;
    }
  }
`;

export const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;

  .promoTitle div {
    font-weight: 700;
    ${pxToCssFont(18, 24)};
    font-family: ${fonts.regular};
  }
`;

export const OfferContainerMobile = styled.div`
  .cta {
    div {
      font-family: ${fonts.regular};
      text-decoration-line: underline;
      font-weight: 700;
      ${pxToCssFont(16, 22)};
    }
  }
`;

export const Text = styled.p`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(16, 22)};
`;

export const ModalText = styled(Text)`
  width: fit-content;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const BigText = styled(Text)`
  ${pxToCssFont(18, 24)};

  @media (max-width: ${breakpoints.tablet}) {
    ${pxToCssFont(14, 18)};
  }
`;

export const HugeText = styled(Text)`
  margin: 0;
  font-size: 2.25rem;
  line-height: unset;
  margin-left: 4px;
  font-family: ${fonts.exbold};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 1.875rem;
  }
`;

export const LightText = styled(BigText)`
  color: ${colors.$7e7e7e};
  // height: 24px;
  margin-top: 0;
  display: flex;
  align-items: center;
  ${pxToCssFont(18, 24)};

  @media (max-width: ${breakpoints.tablet}) {
    ${pxToCssFont(16, 22)};
  }
`;

export const BottomText = styled(Text)`
  margin-top: 16px;
  ${pxToCssFont(18, 24)};
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: ${breakpoints.tablet}) {
    ${pxToCssFont(16, 22)};
  }
`;

export const ModalDetailTitle = styled.h3`
  ${pxToCssFont(30, 38)}
  margin-top: 0;
`;

export const ModalDetailText = styled.div`
  .div {
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    font-weight: 400;
    color: ${colors.$262626};
    margin: 0;
    max-width: none;
  }
`;

export const ModalTitle = styled.div`
  .div {
    margin: 0;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  .div {
    margin: 0;
  }
`;
