import React, { useLayoutEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDeviceType } from '@vfit/shared/hooks';
import { LoggerInstance } from '@vfit/shared/data-access';
import { CustomText, Shadow } from '@vfit/shared/atoms';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { Player } from '@lottiefiles/react-lottie-player';
import { colors, purify } from '@vfit/shared/themes';
import * as O from './offerMap.style';
import { IOfferMap } from './offerMap.models';
import { MAP_CONFIG, MAP_OPTIONS } from './offerMap.utils';
import Promo from '../Promotion/promotion';
import VfModal from '../VfModal/vfModal';
import ShadowsGroup from '../ShadowsGroup/shadowsGroup';
import Pills from '../Pills/pills';
import Inflation from '../Inflation/inflation';

const OfferMap = ({
  coords,
  offer,
  isLoading,
  mapConfig,
  lottie,
  isAlternativeOffer = false,
  isLoadingCoords = false,
  inflation,
  isGlobalPromoActive,
}: IOfferMap) => {
  const { isDesktop } = useDeviceType();
  const [mapHeight, setMapHeight] = useState(300);
  const mapRef = useRef<HTMLDivElement>(null);
  const {
    title,
    topCtaLabel,
    topCtaAction,
    bottomTitle,
    subDescTitle,
    subDescription,
    description,
    originalPrice,
    currentPrice,
    promoList,
    infoLabel,
  } = offer;
  const offerCardFromTop = 64;
  const [openModal, setOpenModal] = useState(false);
  const [pCurrentPrice] = purify([currentPrice || '']);

  useLayoutEffect(() => {
    if (isDesktop) {
      const OfferCardHeight = mapRef?.current?.clientHeight;
      setMapHeight((s) =>
        OfferCardHeight == undefined ? s : OfferCardHeight + offerCardFromTop * 2
      );
    }
  }, [isDesktop, isLoading]);

  const myLocationSize = isDesktop
    ? { height: 77.14, width: 60.68 }
    : { height: 56.02, width: 46.56 };

  const renderMarkers = (
    map: any,
    maps: any,
    lat: number,
    lng: number,
    idMarker: number,
    icon: any
  ) => {
    const marker = new maps.Marker({
      position: { lat, lng },
      icon,
      map,
      zIndex: 60,
    });
    LoggerInstance.debug('OfferMap', marker);
  };

  const offerMap = (key: string) => (
    <O.ManageMapHeight key={key} height={isDesktop ? mapHeight : null}>
      {isLoadingCoords && <Shadow height={isDesktop ? mapHeight : 160} />}
      {!isLoadingCoords && (
        <GoogleMapReact
          bootstrapURLKeys={MAP_CONFIG}
          zoom={isDesktop ? 18 : 16}
          options={MAP_OPTIONS}
          draggable={false}
          center={coords}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            renderMarkers(map, maps, coords?.lat, coords?.lng, -1, {
              scaledSize: new google.maps.Size(myLocationSize.height, myLocationSize.width),
            });
          }}
        />
      )}
    </O.ManageMapHeight>
  );

  // INFLATION SHOPPING CART (?)

  const subCard = () => (
    <O.SubCard>
      <O.Justify bottomPart>
        {isLoading ? (
          <ShadowsGroup />
        ) : (
          <>
            {infoLabel && (
              <Pills pills={[infoLabel]} variant={colors.$00697c} opacity={1} />
            )}
            {bottomTitle && <O.BigText>{bottomTitle}</O.BigText>}
            {(originalPrice || currentPrice) && (
              <O.Prices>
                {originalPrice && <del>{originalPrice}</del>}
                {pCurrentPrice && (
                  <O.HugeText
                    dangerouslySetInnerHTML={{
                      __html: pCurrentPrice,
                    }}
                  />
                )}
              </O.Prices>
            )}
            {subDescTitle && <O.LightText>{subDescTitle}</O.LightText>}
            {subDescription && (
              <div className="subDescription">
                <CustomText text={subDescription} textAlign="left" />
              </div>
            )}
            {inflation?.text && !isGlobalPromoActive && (
              <Inflation
                InflationTitle={inflation?.text}
                popup={inflation?.popup}
                color={inflation?.color}
                inflationDisclaimer={inflation?.disclaimer}
              />
            )}
            {description && (
              <div className="description">
                <CustomText text={description} textAlign="left" />
              </div>
            )}
            {promoList?.elements && promoList?.elements?.length > 0 && (
              <O.PromoContainer>
                <div className="promoTitle">
                  <CustomText text={promoList.title} textAlign="left" />
                </div>
                {React.Children.toArray(
                  promoList?.elements?.map((promotion) => <Promo promo={promotion} />)
                )}
              </O.PromoContainer>
            )}
            {inflation?.text && isGlobalPromoActive && (
              <Inflation
                InflationTitle={inflation?.text}
                popup={inflation?.popup}
                color={inflation?.color}
                inflationDisclaimer={inflation?.disclaimer}
                isGlobalPromo={isGlobalPromoActive}
              />
            )}
            {isAlternativeOffer && (
              <>
                <O.BottomText onClick={() => setOpenModal(true)}>
                  {' '}
                  {mapConfig?.labelCta}{' '}
                </O.BottomText>
                <VfModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
                  <>
                    <O.ModalDetailTitle>{mapConfig?.modalTitle}</O.ModalDetailTitle>
                    <O.ModalDetailText>
                      <CustomText
                        textAlign="left"
                        text={mapConfig?.modalContent || ''}
                        size={18}
                        lineHeight={24}
                      />
                    </O.ModalDetailText>
                  </>
                </VfModal>
              </>
            )}
          </>
        )}
      </O.Justify>
    </O.SubCard>
  );

  const offerContent = () => {
    const offerText = (
      <>
        <O.Justify>
          {topCtaLabel && topCtaAction && !isLoading && (
            <O.ModalText onClick={topCtaAction}>{topCtaLabel}</O.ModalText>
          )}
          {isLoading ? (
            <div>
              {lottie ? (
                <Player
                  src={requireStaticImageAtomComponent(lottie).src}
                  className="player"
                  loop
                  autoplay
                />
              ) : (
                <Shadow height={70} />
              )}
            </div>
          ) : (
            <O.Title>
              <CustomText text={title} textAlign="left" />
            </O.Title>
          )}
        </O.Justify>
        {subCard()}
      </>
    );
    if (isDesktop)
      return (
        <O.OfferContainer ref={mapRef} top={offerCardFromTop}>
          {offerText}
        </O.OfferContainer>
      );
    return <O.OfferContainerMobile>{offerText}</O.OfferContainerMobile>;
  };

  return (
    <O.OfferMapContainer>
      {offerMap(isDesktop ? 'between' : 'card')}
      {offerContent()}
    </O.OfferMapContainer>
  );
};

export default OfferMap;

